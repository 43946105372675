import { compose } from 'ramda'
import { useDispatch } from 'react-redux'
import { setToast } from 'redux/app'

const useDispatchToastMessage = () => {
  const dispatch: React.Dispatch<any> = useDispatch()
  const dispatchSetToast: React.Dispatch<string> = compose(dispatch, setToast)
  return dispatchSetToast
}

export default useDispatchToastMessage
