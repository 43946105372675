import { AppState } from 'redux/app/types'
import { BucketViewPayload } from 'redux/types'
import { createSlice } from '@reduxjs/toolkit'
import { defaultSidebarItem } from 'utils/constants'
import { handleSendMessage } from 'redux/app/utils/handlers'

export const initialState = {
  DISPLAY: 'DASHBOARD',
  TOAST: { status: null, message: '' },
  FIELDMSG: {},
  currentSidebarItem: defaultSidebarItem,
  highlightedQuestion: '',
  parentSidebarItem: null,
  loading: null,
  currentForm: null,
  selectedInternAndBucket: { code: '', parentCode: '' },
  changeView: null,
  userInformation: null,
  currentDetailView: { targetCode: '', detailViewType: '' },
  tinyMCEKey: null,
}

const appSlice = createSlice({
  name: 'app',
  initialState: initialState as AppState,
  reducers: {
    sendMessage: (state, { payload }) => {
      handleSendMessage(payload.data)
      if (payload?.data?.redirect) {
        state.FORM = null
      }
      state.highlightedQuestion = ''
      state.lastSentMessage = { time: new Date(), ...payload }
    },
    setDisplay: (state, { payload }) => {
      state.DISPLAY = payload
    },
    highlightQuestion: (state, { payload }) => {
      state.highlightedQuestion = payload
    },

    setCurrentSidebarItem: (state, { payload }) => {
      state.currentSidebarItem = payload
    },
    setParentSidebarItem: (state, { payload }) => {
      state.currentForm = null
      state.parentSidebarItem = payload
    },

    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    setCurrentForm: (state, { payload }) => {
      state.currentForm = payload
      state.currentSidebarItem = ''
    },
    setSelectedInternAndBucket: (state, { payload }: { payload: BucketViewPayload }) => {
      state.selectedInternAndBucket = {
        code: payload?.code,
        parentCode: payload?.parentCode,
        opportunityCode: payload?.opportunityCode,
      }
    },
    setChangeView: (state, { payload }) => {
      state.changeView = payload
    },
    setUserInformation: (state, { payload }) => {
      state.userInformation = payload
    },
    setCurrentUserDetailObject: (state, { payload }) => {
      state.currentDetailView = payload
    },
    setToast: (state, { payload }) => {
      state.TOAST = payload
    },
    setTinyMCEKey: (state, { payload }) => {
      state.tinyMCEKey = payload
    },
  },
})

export const {
  sendMessage,
  setDisplay,
  highlightQuestion,
  setCurrentSidebarItem,
  setParentSidebarItem,
  setLoading,
  setCurrentForm,
  setSelectedInternAndBucket,
  setChangeView,
  setUserInformation,
  setCurrentUserDetailObject,
  setToast,
  setTinyMCEKey,
} = appSlice.actions
export default appSlice.reducer
