import { createAsyncThunk } from '@reduxjs/toolkit'

export const fetchSelectionData = createAsyncThunk(
  'fetchSelectionItems',
  async ({ fetchData, endpoint }) => {
    try {
      const response = await fetchData(endpoint)
      const items = response?.data?.data?.items

      return items
    } catch (error) {
      console.error(error)
    }
  },
)
