import { DBState } from 'redux/db/types'
import { createSlice } from '@reduxjs/toolkit'
import { fetchSelectionData } from 'redux/db/utils/fetch-dropdown-items'
import formatApiDataToBeDispatched from 'redux/db/utils/format-api-data'

export const initialState = {}

const db = createSlice({
  name: 'db',
  initialState: initialState as DBState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSelectionData.fulfilled, (state, action) => {
      formatApiDataToBeDispatched(state)(action.payload)
    })
  },
})
export default db.reducer
