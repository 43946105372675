import { userRolesList } from 'app/helpers/constant'
import deepFreeze from 'utils/helpers/object-functions/deep-freeze'

export const rootPcmCode = 'PCM_CONTENT'
export const editProfileCode = 'EDIT_PROFILE_FORM'
export const viewProfileCode = 'VIEW_PROFILE_FORM'

export const SIDEBAR_WIDTH = '15.63rem'
export const SIDEBAR_WIDTH_SM = '6.75rem'
export const INTERNMATCH_LOGO_WIDTH = '9.75rem'
export const INTERNMATCH_LOGO_WIDTH_SM = '2.25rem'
export const LOGO_WIDTH = '7.5rem'
export const LOGO_WIDTH_SM = '6.25rem'
export const ACKMESSAGEKEY = 'ACKMESSAGE'
export const maxNumberOfRetries = 5
export const maxRecursiveDepth = 50
export const maxNumberOfApplications = 5

export const dateOfBirthQuestionCode = 'QUE_DATE_OF_BIRTH'
export const eligibleAge = 18
export const lojing = 'LOJING'
export const projectCodeString = 'PROJECT'
export const internmatch = 'INTERNMATCH'
export const pcmKeyDefault = `PCMINFORMATION`
export const tableSbeLocation = 'PRI_LOC1'
export const defaultSidebarItem = 'QUE_DASHBOARD'
export const nameAttributeKey = 'PRI_NAME'
export const firstNameAttributeKey = 'PRI_FIRSTNAME'
export const addItemIconColourInternmatch = '#063231'
export const internshipTableQuestionCode = 'QUE_TABLE_INTERNSHIP_OPPORTUNITIES'
export const internshipTableParentQuestionCode = 'QUE_SIDEBAR'
export const internTableQuesionCode = 'QUE_TABLE_INTERNS'
export const internTableSidebarCode = 'QUE_CONTACTS'
export const applicationsTableQuestionCode = 'QUE_TABLE_APPLICATIONS'
export const userKey = 'USER'
export const matchesVisaQuestionCode = 'QUE_MATCHES_VISA'
export const matchesEmpContractQuestionCode = 'QUE_MATCHES_EMPLOYMENT_CONTRACT'
export const websiteInvestigatedQuestionCode = 'QUE_WEBSITE_INVESTIGATED'
export const adminConfirmedQuestionCode = 'QUE_ADMIN_CONFIRMED'
export const sidebarQuestionCode = 'QUE_SIDEBAR'

export const iconColor = '#FFFFFF'
export const selectedSidebarBoxColor = '#CDD6D6'
export const iconColorOnHighlight = '#EA5024'

export const allowedVideos = 'video/*'
export const allowedImages = '.png, .jpeg, .jpg'
export const allowedDocuments = `${allowedImages}, application/pdf, .doc, .docx, .xml, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .dot, .rtf, .odt, .txt`

export const hostCompanyRoute = 'company'
export const companyTagsRoute = 'company_tags'
export const hostCompanyTableRoute = 'hc'
export const hostCompanyDropdownRoute = 'dropdown/hc'
export const hostCompanyListRoute = `${hostCompanyRoute}/selection`
export const hostCompanyRepRoute = 'hcreps'
export const staffsRoute = 'hcstaff'
export const hostCompanySupervisorAndRepsListRoute = `${staffsRoute}/selection`
export const educationProviderRoute = 'education-provider'
export const educationProviderListRoute = `${educationProviderRoute}/selection`
export const educationProviderRepRoute = 'epreps'
export const educationProvioderStaffRoute = 'epstaff'
export const associatedEducationProvioderStaffRoute = `${educationProviderRoute}/selection`
export const programProviderRoute = 'program-provider'
export const programProviderListRoute = `${programProviderRoute}/selection`
export const programProviderRepTableRoute = `${programProviderRoute}/staff/table`
export const programProviderStaffRoute = 'ppreps'
export const ppStaffsRoute = 'ppstaff'
export const internshipOpportunitiesRoute = 'opportunities'
export const filledOpportunitiesRoute = `${internshipOpportunitiesRoute}/filled`
export const noApplicationOpportunitiesRoute = `${internshipOpportunitiesRoute}/noapplications`
export const archiveOpportunities = `${internshipOpportunitiesRoute}/archive`
export const applicationsRoute = 'applications'
export const applyInternshipRoute = `${applicationsRoute}/apply`
export const applicationRejectRoute = `${applicationsRoute}/reject`
export const internsRoute = 'interns'
export const withdrawInternsRoute = `${internsRoute}/reject`
export const completeInternsListRoute = `${internsRoute}/selection`
export const agentsRoute = 'agents'
export const agentsRouteDropdown = 'agents/selection'
export const actionsEndpoint = 'actions'
export const dropdownsRoute = 'dropdowns/items'
export const completeAgentsListRoute = `${agentsRoute}/selection`
export const countriesRoute = 'countries'
export const officeLocation = `${agentsRoute}/office-location/selection`
export const industriesRoute = 'industries'
export const companyIndustriesRoute = 'dropdown/company-industry'
export const statesRoute = 'states'
export const specialisationsRoute = 'specialisations'
export const userRoute = 'user'
export const interviewsRoute = 'interviews'
export const interviewsForApplicationRoute = 'interviews/application'
export const internshipsRoute = 'internships'
export const terminateInternshipRoute = `${internshipsRoute}/reject`
export const logbookRoute = 'logbooks'
export const resetPasswordRoute = 'settings/change-password'
export const termsAndConditionsRoute = 'settings/accept-terms'
export const ratingsRoute = 'rating'
export const cohortsRoute = 'cohorts'
export const documentsRoute = 'documents'
export const internshipDocumentRoute = 'internship-document'
export const internsDocumentsRoute = `${documentsRoute}/intern`
export const hcDocumentsRoute = `${documentsRoute}/host-company`
export const departmentsRoute = 'departments'
export const settingsRoute = 'settings'
export const timezonesRoute = `${settingsRoute}/timezones`
export const changePasswordRoute = `${settingsRoute}/change-password`
export const officeLocationRoute = `company/companyCode/locations`
export const specialisationTextRoute = `${internshipOpportunitiesRoute}/specialisation-text`
export const offerInterviewRoute = `interview/offer`
export const projectConfigRoute = 'projects'

export const backToTableView = 'Back to Table View'

export const editProfileActionCode = 'ACT_EDIT'
export const viewProfileActionCode = 'ACT_VIEW'
export const archiveOpportunityActionCode = 'ACT_ARCHIVE'
export const hcSupervisor = 'Host Company Supervisor'
export const internReviewingInterviewCode = 'ACT_REVIEW_INTERVIEW'
export const signInternshipAgreementCode = 'ACT_SIGN_INTERNSHIP_AGREEMENT'
export const shortlistActionCode = 'ACT_SHORTLIST'
export const offerInternshipActionCode = 'ACT_OFFER_INTERNSHIP'
export const viewLogbooksActionCode = 'ACT_VIEW_LOGBOOKS'
export const viewApplicationActionCode = 'ACT_VIEW_APPLICATION'

export const internType = 'INTERN'
export const companiesType = 'COMPANIES'
export const applicationType = 'APPLICATIONS'
export const opportunitiesType = 'OPPORTUNITY'

export const noApplicationsMessage = 'No Applications'
export const agentsNoApplications = 'No Applications'
export const agentsNoOpportunities = 'No Opportunities'
export const agentsNoActiveInterns = 'No Active Interns'
export const agentsNoLogbooks = 'No Logbooks'

export const hcStaffNoApplications = 'No Applications'
export const hcStaffNoOpportunities = 'No Opportunities'
export const hcStaffNoActiveInterns = 'No Active Interns'
export const hcStaffNoLogbooks = 'No Logbooks'
export const hcStaffNoComplianceDocumentsMessage = 'No Compliance Documents'

export const epStaffNoApplications = 'No Applications'
export const epStaffNoOpportunities = 'No Opportunities'
export const epStaffNoActiveInterns = 'No Active Interns'
export const epStaffNoLogbooks = 'No Logbooks'

export const ppStaffNoApplications = 'No Applications'
export const ppStaffNoOpportunities = 'No Opportunities'
export const ppStaffNoActiveInterns = 'No Active Interns'
export const ppStaffNoLogbooks = 'No Logbooks'

export const noApplicationsMessageInterns = `You do not have any applications.
You can search and apply for internships once you have
completed your personal profile and any
documentation requested on the Compliance Documentation tab of your Dashboard.`
export const noApplicationsMessageDJPInterns = `You do not have any applications.
You can search and apply for internships once you have
completed your personal profile and any
documentation requested on the Compliance Documentation tab of your Dashboard.`
export const noOpportunitiesMessage = 'No Opportunities'
export const noComplianceDocumentsMessage = 'No Compliance Documents'
export const noComplianceDocumentsMessageInterns =
  'You will be required to sign compliance documentation once you have been selected for an internship.'
export const noActiveInternsMessage = 'No Active Interns'
export const noLogbooksMessage = 'No Logbooks'
export const noLogbooksMessageInterns = `Once you have started your internship you will complete a daily logbook entry. Your logbook will be the perfect reminder for when you need to update your resume with all of the amazing things you experienced while on internship`
export const noStaffsMessage = 'No Staff'
export const noInternshipsMessage = `You cannot yet search for an internship.
You can search and apply for internships once you have
completed your personal profile and any
documentation requested on the Compliance Documentation tab of your Dashboard.`
export const noInternshipsMessageDJPIntern = `You cannot yet search for an internship.
  You can search and apply for internships once you have completed your personal profile.`
export const emptyInternshipMessage = 'No internships'
export const noCurrentSearchText = 'No current searches!'

export const internAppliedMaxApplicationMessage =
  'This intern has already applied for maximum number of allowed applications.'
export const archiveOpportunitySuccessfulMessage = 'Opportunity has been archived successfully.'
export const opportunityFilledMessage = 'This opportunity has been filled.'

export const oldAndNewPasswordsSameMessage = 'Old password and new password cannot be same.'
export const configureTimezoneMessage =
  'You are currently using system timezone. You can change your timezone'

export const roleAgent = userRolesList.Agent
export const roleIntern = userRolesList.Intern
export const roleSuperAgent = userRolesList.SuperAgent
export const roleAdmin = userRolesList.Admin
export const roleHcRep = userRolesList.HCRep
export const roleHcSupervisor = userRolesList.HCSupervisor
export const roleEPStaff = userRolesList.EPRep
export const rolePPStaff = userRolesList.PPRep
export const rolePPSupervisor = userRolesList.PPSupervisor

export const maxAllowedInternshipApllicationMessage =
  'This intern has already applied maximum applicable applications.'
export const nonActiveOpportunitiesArchiveMessage = 'Non active opportunities cannot be applied.'
export const terminateInternshipDisableMessage = 'This internship application has not been started.'
export const userRoleIntern = 'Intern'
export const logbooksNotCreatedMessage =
  'This applications has not yet been started to generate logbooks.'

//Tableau Analytics Button Labels
export const tableauAnalyticsCategoriesLabels = Object.freeze({
  agentsManagingInterns: 'Agents Managing Interns',
  companiesAndTags: 'Companies And Tags',
  companiesRegistrationTimeline: 'Companies Registration Timeline',
  internsInternshipsStatus: 'Interns Internships Status',
  internsStatus: 'Interns Status',
  opportunitiesAndApplications: 'Opportunities And Applications',
  opportunitiesByCompanies: 'Opportunities By Companies',
})

//Tableau Button Labels
export const tableauButtonLabels = Object.freeze({
  internAnalytics: 'Intern Analytics',
  analytics: 'Analytics',
  opportunityAnalytics: 'Opportunity Analytics',
})

export const rejectLogbookReasons = deepFreeze([
  {
    value: 'Intern did not complete the tasks indicated within this entry',
    label: 'Intern did not complete the tasks indicated within this entry',
  },
  {
    value: 'Intern completed more than the tasks indicated within this entry',
    label: 'Intern completed more than the tasks indicated within this entry',
  },
  {
    value: 'Intern did not attend their internship on this day',
    label: 'Intern did not attend their internship on this day',
  },
  { value: 'Inaccurate details within this entry', label: 'Inaccurate details within this entry' },
  { value: 'Hours are not reflected accurately', label: 'Hours are not reflected accurately' },
])

export const errorMessage =
  'There was an error while trying to fetch data. Please refresh and try again.'
export const autoRejectedLabel = 'Auto Rejected'
export const terminatedCardLabel = 'Terminated'
export const candidateNotAvailableLabel = 'Candidate no longer available'

export const formQuestionCode = 'QUE_ADD_ITEMS_GRP'

export const uniqueCheck = 'unique/check'
export const defInternshipOpportunity = 'DEF_INTERNSHIP_OPPORTUNITY'
export const defHostCpy = 'DEF_HOST_CPY'

export const attributeDefinitions = Object.freeze({
  internshipOpportunity: 'DEF_INTERNSHIP_OPPORTUNITY',
  hostCompany: 'DEF_HOST_CPY',
  intern: 'DEF_INTERN',
  application: 'DEF_APPLICATION',
  internship: 'DEF_INTERNSHIP',
})

export const attributeCodeLNK = Object.freeze({
  withdrawReasons: 'LNK_WITHDRAW_REASON',
  rejectingByAgent: 'LNK_REJECTING_REASON_BY_AGENT',
  terminationReason: 'LNK_TERMINATION_REASON',
})

export const entityTypeLogbooks = 'LOGBOOKS'
