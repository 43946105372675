import { reduce, keysIn } from 'ramda'
import { isArray, isObject } from 'utils/helpers/type-checking/is-type'

/**
 * Recursively freezes all the element of the passed in value,
 * making  the entire object or array immutable
 * @param {*} value
 * @returns
 */
const deepFreeze = value =>
  Object.freeze(
    reduce(
      (acc, elem) => {
        const valueAt = value[elem]

        if (isArray(valueAt) || isObject(valueAt)) {
          acc[elem] = deepFreeze(valueAt)
        } else {
          acc[elem] = valueAt
        }
        return acc
      },
      isArray(value) ? [] : {},
      keysIn(value),
    ),
  )

export default deepFreeze
