import { map } from 'ramda'

const formatApiDataToBeDispatched = state => data => {
  map(item => {
    const key = item?.code || ''

    if (!!key && !state[key]) {
      state[key] = item
    } else if (state[key]) {
      console.error('Key is already present in DB store, ignoring this item.')
    } else {
      return
    }
  })(data || [])
}

export default formatApiDataToBeDispatched
