import axios from 'axios'
import { HOST } from 'config/genny'

const setupGoogleApi = async () => {
  const maps = document.createElement('script')
  const res = await axios.get(`${HOST}/googleapi/v1/map`, {})
  const src = URL.createObjectURL(new Blob([res.data], { type: 'text/javascript' }))
  maps.setAttribute('src', src)
  document.head.appendChild(maps)
}
export default setupGoogleApi
