import { compose, equals, identity } from 'ramda'

import EventBus from '@vertx/eventbus-bridge-client.js'
import { VERTX_URL } from 'config/genny'
import createSendMessage from 'vertx/utils/create-send-message'
import { getSessionIdFromToken } from 'keycloak/get-token-from-url'
import { isNotEmpty } from 'utils/helpers/null-checking/is-null-or-undefined'
import makeAuthInitData from 'vertx/utils/make-auth-init-data'
import selectToken from 'keycloak/utils/select-token'
import sendAuthInit from 'config/send-auth-init'
import { sendMessage } from 'redux/app'
import { tokenFromUrl } from 'config/get-api-config'
import { useDispatch } from 'react-redux'
import useDispatchToastMessage from 'app/helpers/dispatch-toast-message'
import { useKeycloak } from '@react-keycloak/web'

export const eventBus = new EventBus(VERTX_URL)

eventBus.enableReconnect(true)

let onSendMessage = identity

const VertxContainer = () => {
  const { keycloak } = useKeycloak()
  const { login } = keycloak
  const { sessionId: kSessionId, token: tokenFromKeycloak } = keycloak
  const urlSessionId = tokenFromUrl ? getSessionIdFromToken(tokenFromUrl) : null

  const token = selectToken({ tokenFromKeycloak, tokenFromUrl })
  const sessionId = kSessionId || urlSessionId

  const dispatch = useDispatch()
  const onSendMsg = compose(dispatch, sendMessage)
  const dispatchToast = useDispatchToastMessage()

  eventBus.onerror = error => {
    console.error('There was an error trying to connect to the event bus!', error)
  }

  if (!(token && sessionId)) login({ redirectUri: `${window.location.href}` })
  if (token && sessionId && !eventBus.handlers[sessionId]) {
    try {
      eventBus.registerHandler(`${sessionId}`, (error, { body }) => {
        const { cmd_type, msg_type, exec, code, message } = body || {}
        const isDownloadableFile = cmd_type === 'DOWNLOAD_FILE' && msg_type === 'CMD_MSG' && exec
        const isToast = cmd_type === 'TOAST'

        if (isToast && equals('ERROR')(code)) {
          dispatchToast({
            status: code,
            message: message,
          })
        }

        if (isDownloadableFile) {
          console.log(
            '%c  Download file link   ',
            'background: silver; color: black; padding: 0.5rem',
            code,
          )
          if (isNotEmpty(code)) {
            window.open(code)
          }
          return
        }
        if (error) {
          console.error('An error occured, please try again!', error)
        }
      })
    } catch (err) {
      console.error(err)
      window.location.reload()
    }

    sendAuthInit({ token })

    eventBus.send(
      'address.inbound',
      {
        data: makeAuthInitData({ sessionId, token }),
      },
      { Authorization: `Bearer ${token}` },
    )

    onSendMessage = createSendMessage(token, onSendMsg)
  }

  return null
}

export default VertxContainer
export { onSendMessage }
