import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom'
/* eslint-disable react-hooks/exhaustive-deps */
import { Suspense, lazy } from 'react'

import { CSSReset } from '@chakra-ui/react'
import GitVersionInfo from 'utils/helpers/git_version'
import Vertx from 'vertx'

const Display = lazy(() => import('app/Display'))

const App = (): React.JSX.Element => {
  return (
    <>
      <CSSReset />
      <Vertx />
      <Router>
        <Switch>
          <Route exact path="/" component={() => <Redirect to={{ pathname: '/home' }} />} />
          <Route
            path="/home"
            component={() => (
              <Suspense fallback={<div />}>
                <Display />
              </Suspense>
            )}
          />
          <Route path="/version" component={() => <GitVersionInfo />} />
          <Route path={`/public`} exact component={() => <Redirect to={{ pathname: '/home' }} />} />
          <Route path={'/*'} component={() => <Redirect to={{ pathname: '/home' }} />} />
        </Switch>
      </Router>
    </>
  )
}
export default App
